<template>
  <b-container data-aos="fade-up">
    <h1 class="mt-5 mb-2 text-primary">
      Policy for General Data Protection Regulations (GDPR)
    </h1>

    <b-alert show variant="warning" class="text-dark h6 pt-4">
      <p>
        On joining the car scheme there is an assumption that you are happy for
        the following personal data to be held securely about you.
      </p>
      <p>
        If this is not the case, you must contact the Scheme Co-ordinator on
        <b-link href="tel:07717178800">07717-17-88-00</b-link>.
      </p>
    </b-alert>
    <h2 class="text-primary">What Data do we collect?</h2>
    <h3 class="text-info">Volunteer Drivers</h3>
    <p>When a driver first joins the scheme they are required to provide:</p>
    <ul>
      <li>
        Full name, date &amp; place of birth and address (plus previous address
        if they have not lived at current address for at least 2 years).
      </li>
      <li>telephone number (landline and/ or mobile) and email address.</li>
      <li>
        DVLC Driving License number, make &amp; model of car with current car
        registration plate.
      </li>
      <li>Contact details for a nominated person in case of emergency.</li>
      <li>Car Insurance Policy number confirming comprehensive insurance.</li>
    </ul>
    <h3 class="text-info">Users of the Car Scheme</h3>
    <p>When a user first joins the scheme they are required to provide:</p>
    <ul>
      <li>Full name and address</li>
      <li>Telephone number (landline and/or mobile) and email address.</li>
      <li>Contact details for a nominated person in case of emergency.</li>
    </ul>
    <p>
      This information is either received via a form on our website (<b-link
        to="/"
        >thwvcs.co.uk</b-link
      >) or passed verbally to the Co-ordinator who will record it on a paper
      form which is securely filed.
    </p>

    <h2 class="text-primary">How is data stored?</h2>
    <p>
      The Co-ordinator records all data for drivers and users on a Car Scheme
      Membership Sheet using a word processing application. This is updated
      regularly as drivers/users join or leave the scheme. Names, phone numbers
      &amp; email addresses are stored in the Car Scheme dedicated mobile phone
      which is only used by the Co-ordinator or her designated representative -
      this is password protected.
    </p>

    <h2 class="text-primary">Who do we share data with?</h2>
    <p>
      The Cordinator is required to give out the name/address and a contact
      phone number of a user to a volunteer driver. This is to enable to driver
      to contact a passenger the day before the trip takes place and to ensure
      contact is made so the trip can be successfully undertaken. Drivers are
      asked to delete a user’s details from their personal phone once a trip has
      been completed.
    </p>

    <h2 class="text-primary">How is data kept secure</h2>
    <p>
      The Membership list of the drivers and users is password protected and is
      held on the Co-ordinator’s laptop. All personal data on the Car scheme
      mobile phone is password protected. No personal information is stored on
      our website.
    </p>

    <h2 class="text-primary">How long is data retained?</h2>
    <p>
      Data is retained for as long as a driver or user wishes to be a member of
      the Car Scheme. Once they have informed the Co-ordinator they no longer
      wish to be part of the Scheme their details are deleted from the
      Membership List, scheme mobile phone and paper copies of their personal
      data is shredded.
    </p>
  </b-container>
</template>

<script>
import { BAlert } from "bootstrap-vue";
export default {
  name: "Privacy",
  components:{
    BAlert
  }
};
</script>

<style scoped>
a:link {
  color: inherit;
  background-color: transparent;
  text-decoration: none;
}

a:visited {
  color: inherit;
  background-color: transparent;
  text-decoration: none;
}

a:hover {
  color: inherit;
  background-color: transparent;
  text-decoration: underline;
}

a:active {
  color: inherit;
  background-color: transparent;
  text-decoration: underline;
}
</style>